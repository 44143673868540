import React from 'react'
import './ProductCard.css';
import ColourCirlce from './ColourCircle';

export default function ProductCard(props) {
    const productColors = props.data.colours;
    function handleClick(){
        props.open(props.data)
    }

    const colors = props.data.colours.map(color =>{
        
        return <ColourCirlce color={color}/>
    })

    return (
        <div className='productCard' onClick={handleClick}>
                <img loading='lazy' src={props.data.image} alt='Product'/>
           
            <div className='product-description'>
                <div className='product-colors'>
                {colors}
                </div>
            
            
            <small className='product-brand'>{props.data.brand}</small>
            <small className='product-details'>{props.data.description}</small>
            
            </div>
            
            <div className='price'>
            <small>US ${props.data.price}</small>

            </div>
            
            

        </div>
    )
  
}
