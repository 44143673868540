import React, { Component, useRef } from "react";
import "./Home.css";
import { motion, useScroll, useTransform } from "framer-motion";
import NavigationBar from "../components/NavigationBar";
import FrontPage from "../components/FrontPage.js";
import FrontPage2 from "../components/FrontPage2.js";
import Form from "../components/Form";
import Footer from "../components/Footer";
import CarouselComponent from "../components/CarouselComponent";
import { useAuth0 } from "@auth0/auth0-react";
import Cart from "../components/Cart";

export default function Home() {
  const [cartOpen, setCartOpen] = React.useState(false);
  const openCart = () => {
    setCartOpen(!cartOpen);
  };

  return (
    <>
      <div className="wrapper">
        <NavigationBar open={openCart} />

        <FrontPage />
        <FrontPage2 />

        {cartOpen ? <Cart close={openCart} /> : <></>}
        <footer className="front-page-footer">
          <div>
            <p className="front-page-footer-text">
              Sign up for promotions, tailored new arrivals, stock updates and
              more – straight to your inbox
            </p>
          </div>
          <Form />
        </footer>
        <Footer />
      </div>
    </>
  );
}
