import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Home from './pages/Home.js';
import { AnimatePresence } from 'framer-motion';
import Shop from './pages/Shop.js';
import React, { Component, useRef } from 'react';
import './App.css';
import Loading from './pages/Loading.js';

function App() {
  const [loading, setLoading] = React.useState(true); 

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 3000)
 }, [])
  return (
    <div className="App">

      {
        loading ? (<Loading/>):(<AnimatePresence wait >
          <BrowserRouter> 
          <Routes>
          <Route path ='/' element ={<Home/>}/>
          <Route path ='/shop' element ={<Shop/>}/>
          </Routes>
          </BrowserRouter>
          </AnimatePresence>)
      }
      
    
  </div>
  );
}

export default App;
