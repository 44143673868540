import React, { Component, useRef } from "react";
import "./Cart.css";
import CartItem from "./CartItem";
import store from "../redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Cart(props) {
  const [EmailChecked, setEmailChecked] = React.useState();
  const [SmsChecked, setSMSChecked] = React.useState();
  const phoneValue = React.useRef();
  const emailValue = React.useRef();
  const formData = useRef({});
  const cartList = React.useRef();
  const total = React.useRef();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /\+[0-9]+/i;
  const isLogged = React.useRef();
  //const { user, isAuthenticated } = useAuth0();
  //const { loginWithRedirect } = useAuth0();
  function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(formData.current);
    console.log(data);
  }

  function handleChecked(e) {
    if (e.target.value === "email") {
      setEmailChecked(e.target.checked);
    } else {
      setSMSChecked(e.target.checked);
    }
  }

  const list = React.useRef([]);
  total.current = store.getState().cart.cartTotal;

  cartList.current = store.getState().cart.cart.map((item) => {
    list.current += "\n " + "- " + item.cart.description;
    return <CartItem data={item.cart} quantity={item.noOfItem} />;
  });

  async function placeOrder() {
    if (phoneRegex.test(phoneValue.current)) {
      let message =
        `New Order Request 👖 👕 👟 🧢\n Customer : Edward Bera\n Order :\n ` +
        list.current;
      let message2 = `🙂 Thank you for shopping with Stars 29!\n One of our sales 👤representatives will contact you shortly to begin processing your order.\n`;

      axios.post(
        "https://graph.facebook.com/v17.0/103711299074871/messages?access_token=" +
          process.env.TOKEN,
        {
          messaging_product: "whatsapp",
          to: phoneValue.current.Trim,
          text: { body: message },
        },
      );

      axios.post(
        "https://graph.facebook.com/v17.0/103711299074871/messages?access_token=" +
          process.env.TOKEN,
        {
          messaging_product: "whatsapp",
          to: phoneValue.current.Trim,
          text: { body: message2 },
        },
      );
    }
  }

  return (
    <div className="cart">
      <div className="cart-wrapper">
        <div onClick={props.close}>X</div>
        <div className="cart-header">
          <h1>Checkout</h1>

          <form className="CartContactForm" ref={formData}>
            <div>
              <label>
                <input
                  className="checkbox"
                  value="email"
                  type="checkbox"
                  onChange={handleChecked}
                />
                Email
              </label>
            </div>
            {EmailChecked ? (
              <div className="CartFormEmailDiv">
                <FontAwesomeIcon icon={faEnvelope} size="1x" />

                <input
                  name="email"
                  className="inputbox"
                  placeholder="Email"
                  onChange={(value) => (emailValue.current = value)}
                />
              </div>
            ) : (
              <div> </div>
            )}
            <div>
              <label>
                <input
                  className="checkbox"
                  value="sms"
                  type="checkbox"
                  onChange={handleChecked}
                />
                WhatsApp
              </label>
            </div>
            {SmsChecked ? (
              <PhoneInput
                className="phoneInput"
                placeholder="Enter phone number"
                name="phoneNumber"
                onChange={(value) => (phoneValue.current = value)}
              />
            ) : (
              <div> </div>
            )}
          </form>
          <div>
            <p>
              Cart total <strong>US${total.current}</strong>
            </p>
            <button onClick={placeOrder}>Place order</button>
          </div>
        </div>
        <div className="cart-item-wrapper">{cartList.current}</div>
      </div>
    </div>
  );
}
