import { configureStore } from '@reduxjs/toolkit'
import cartReducer from './cart.reducers';


const store = configureStore({
  reducer: {
    cart: cartReducer,
   
  },
});

export default store;
