import React, { Component } from 'react';
import './CheckOutModal.css';

export default function CheckOutModal(props){

    const openCart = () =>{
        props.checkout()
        props.cart()
    }
    return(
        <div className='CheckOutModal'>
            <h1>Added to Cart</h1>
            <div className='CheckOutModal-Container'>
                <button onClick={openCart}>Proceed to Checkout</button>
                <button onClick={()=>props.checkout()}>Continue Shopping</button>
            </div>

        </div>
    )
}