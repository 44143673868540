import React, { Component } from 'react';
import './Modal.css';
import SizeCard from './SizeCard';
import store from '../redux/store';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

export default function Modal(props) {
    const Style = React.useRef();
    const sizes = React.useRef();
    const selectedSize = React.useRef('');
    const [quantity, setQuantity] = React.useState(1);

    const getSize = (size) =>{
       selectedSize.current = size
    }
    //Function to add items to cart
    const addItemToCart = () => {
        if (selectedSize.current===''){
            toast("Select a size")
        }else{

           props.data.sizes = selectedSize.current
    
        return {
            type: "ADD_ITEM_TO_CART",
                payload: {
                cart: props.data,
                noOfItem: quantity,
                }
            }  
        }
                
        }

    function handleClick(){
       addItemToCart();
      store.dispatch(addItemToCart())
       props.open()
        props.checkout()
       
        
    }

    //Functions to handle quantity increase and decrease
    const increment = () => {
        setQuantity( current => current +1)
    }

    const decrement = () => {
        setQuantity( current => current > 1 ? current-1 : current = 1)
    }

    //Mapping through the array of product sizes
    sizes.current = props.data.sizes.map(size => {
        return <SizeCard getSize ={getSize} data ={size}/>
    })
    
    return(
        <div className='modal' style={Style.current}>
            <div className='close-modal-btn' onClick={props.open}>
                X
            </div>

            <div className='modal-img'>
            <img src={props.data.image} alt='Product'/>
                </div>
                <div className='modal-details'>
                    <div className='modal-header'>
                    <p className='modal-brand'>{props.data.brand}</p>
                    <p className='modal-title'>{props.data.description}</p>
                    <p className='modal-price'>{props.data.price}</p>
                    </div>
                    <div className='modal-sizes'>
                        <p>SELECT SIZE -</p>
                        <div className='modal-sizes-container'>
                       {sizes.current}
                        
                        </div>
                    </div>
                    <div className='modal-footer'>
                        <div className='quantity'>
                            <h1 onClick={decrement} >
                                <>-</></h1>
                            <h2>{quantity}</h2>
                            <h1 onClick={increment}>
                                <>+</></h1>
                        </div>
                        <ToastContainer/>
                        <div onClick={handleClick} className='addtocart-btn'>
                            <p>ADD TO CART</p></div>

                    </div>

                
                </div>

            </div>
    )
  
}
