import React, { Component } from 'react'

export default function SizeCard(props){
    const [selected, setSelected] = React.useState(false);
    var style;
    const selectSize = ()=>{
        setSelected(!selected)
        props.getSize(props.data)
    }

    if (selected){
        style = {
            backgroundColor : "black",
            color : "white"
        }
    }
    
    return(
        <div onClick={selectSize} style={style} className='SizeCard'>
             <div className='modal-size'>
                            <p>{props.data}</p>
                        </div>

        </div>
    )
}