import React, { Component } from 'react';
import './Loading.css';

export default function Loading(){
    return(
        <div className='Loading'>

            <h1 className='waveTextAnimated'>
                <span>S</span>
                <span>t</span>
                <span>a</span>
                <span>r</span>
                <span>s</span>
                <span>2</span>
                <span>9</span>
                <span>.</span>
            </h1>

        </div>
    )
}