import React, { Component } from 'react'


export default function ColourCirlce(props){

    return(
        <div style={{backgroundColor : props.color}} className='ColourCircle'>

        </div>
    )
}