import React, { Component } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "./FrontPage2.css";
import { Link } from "react-router-dom";

export default function FrontPage() {
  const { scrollYProgress } = useScroll({
    offset: ["start", "center"],
  });

  return (
    <section className="FrontPage2">
      <div className="HeaderIMG2">
        <p className="SideText2">SS23</p>
        <img
          loading="lazy"
          src="https://ik.imagekit.io/gnqpjyacq/Stars29/shoot-4.jpg?updatedAt=1699805984978"
          alt="Header"
        />
      </div>
      <div className="HeaderText2">
        <h1>JOIN THE MOVEMENT</h1>
        <p>Express your individuality...</p>
        <Link to="/shop">
          <button>Explore {`>>`}</button>
        </Link>
      </div>
    </section>
  );
}
