import React, { Component, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import "./ShoppingCartIcon.css";

export default function ShoppingCartIcon(props) {
  const counter = useRef();

  counter.current = props.quantity;

  return (
    <div className="CartIcon" onClick={props.open}>
      <FontAwesomeIcon icon={faCartShopping} size="lg" />
      {counter.current > 0 ? (
        <span className="CartItemCounter">{counter.current}</span>
      ) : (
        ""
      )}
    </div>
  );
}
