import React, { Component } from "react";
import "./Shop.css";
import ProductCard from "../components/ProductCard";
import NavigationBar from "../components/NavigationBar";
import Modal from "../components/Modal";
import SideBar from "../components/SideBar";
import Footer from "../components/Footer";
import Cart from "../components/Cart";
import sampleData from "../components/sample.js";
import CheckOutModal from "../components/CheckOutModal";
import { useAuth0 } from "@auth0/auth0-react";

export default function Shop() {
  const [toggle, setToggle] = React.useState(false);
  const [cartOpen, setCartOpen] = React.useState(false);
  const [checkOutModal, setCheckOutModal] = React.useState(false);
  const [products, setProducts] = React.useState();
  const Selected = React.useRef();
  const { user, isAuthenticated, isLoading } = useAuth0();

  //console.log(user)
  //console.log(isAuthenticated)
  //function to open the product detail modal
  const open = (data) => {
    setToggle(!toggle);
    Selected.current = data;
  };

  const openCart = () => {
    setCartOpen(!cartOpen);
  };

  const openCheckOutModal = () => {
    setCheckOutModal(!checkOutModal);
  };

  //Function to receive the filter selection from the sidebar
  const getSelection = (selection) => {
    switch (selection) {
      case "All":
        displayAllProducts();
        break;
      default:
        display(selection);
        break;
    }
  };

  //Display all products
  function displayAllProducts() {
    const productList = sampleData.map((product) => {
      return (
        <ProductCard
          key={product.id}
          id={product.id}
          data={product}
          open={open}
        />
      );
    });
    setProducts(productList);
  }

  //Filtering products
  function display(select) {
    // eslint-disable-next-line array-callback-return
    const productList = sampleData.map((product) => {
      if (product.category === select) {
        return (
          <ProductCard
            key={product.id}
            id={product.id}
            data={product}
            open={open}
          />
        );
      }
    });
    setProducts(productList);
  }

  React.useEffect(() => {
    displayAllProducts();
  }, []);

  return (
    <div className="shop">
      <NavigationBar open={openCart} />
      <div className="shop-inner-wrapper">
        <div className="shop-container">{products}</div>
        {toggle ? (
          <Modal
            data={Selected.current}
            open={open}
            checkout={openCheckOutModal}
          />
        ) : (
          <></>
        )}

        {cartOpen ? <Cart close={openCart} /> : <></>}

        {checkOutModal ? (
          <CheckOutModal checkout={openCheckOutModal} cart={openCart} />
        ) : (
          <></>
        )}
      </div>
      <Footer />
    </div>
  );
}
