import React, { Component } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

// eslint-disable-next-line import/no-anonymous-default-export
export default function(){
    return(
        <footer className='footer'>
            <div className='footer-wrapper'>

            
            <div className='footer-address'>
                <h1>Location</h1>
                <p>Shop 29 </p>
                <p>Haddon & Sly</p>
                <p>Bulawayo</p>
                <p>Zimbabwe</p>
            </div>
            <div className='footer-socials'>
              
                <p><FontAwesomeIcon icon={faInstagram} size='3x'/></p> 
                <p><FontAwesomeIcon icon={faTiktok} size='3x'/></p>               
            </div>
            </div>
            <small className='copyright'>Created & Developed by Edward Bera &copy; 2023 </small>
        </footer>
    )
}