import React, { Component, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./NavigationBar.css";
import ShoppingCartIcon from "./ShoppingCartIcon";
import store from "../redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faUser,
  faHamburger,
  faNavicon,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

export default function NavigationBar(props) {
  const { loginWithRedirect } = useAuth0();
  const { user, isAuthenticated } = useAuth0();
  const [toggle, setToggle] = React.useState(false);

  return (
    <div className="NavigationBar">
      <div className="Nav-Logo">
        <Link to={"/"}>29.</Link>

        {/*<div  onClick={() => loginWithRedirect()}>{
                    isAuthenticated ? (user.given_name) : (
                    <FontAwesomeIcon icon={faUser} size='lg'/>
                    )}</div>*/}
        <div
          className="hamburger"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          {toggle ? (
            <FontAwesomeIcon icon={faClose} size="lg" />
          ) : (
            <FontAwesomeIcon icon={faNavicon} size="lg" />
          )}
        </div>
      </div>
      <div className="NavBar-Content">
        <ul className="Desktop-Nav">
          <li className="NavItem">
            <Link to="/shop">Shop</Link>
          </li>
          <li className="NavItem">
            <Link to="/shop">About</Link>
          </li>
        </ul>
      </div>
      <ShoppingCartIcon
        open={props.open}
        quantity={store.getState().cart.noOfItemInCart}
      />

      {toggle ? (
        <ul className="NavList">
          <li className="NavItem">
            <Link to="/shop">Shop Now</Link>
          </li>
          <li className="NavItem">
            <Link to="/shop">About</Link>
          </li>
          {/* <li className='NavItem' onClick={() => loginWithRedirect()}>{
                    isAuthenticated ? (user.given_name) : (
                    <FontAwesomeIcon icon={faUser} size='lg'/>
                    )}</li> */}
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
}
