import React, { Component, useRef } from "react";
import "./Form.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function Form() {
  const [EmailChecked, setEmailChecked] = React.useState();
  const [SmsChecked, setSMSChecked] = React.useState();
  const phoneValue = React.useRef();
  const formData = useRef({});

  function handleSubmit(e) {
    e.preventDefault();
    const data = new FormData(formData.current);
    console.log(data);
  }

  function handleChecked(e) {
    if (e.target.value === "email") {
      setEmailChecked(e.target.checked);
    } else {
      setSMSChecked(e.target.checked);
    }
  }

  return (
    <form className="contactForm" onSubmit={handleSubmit} ref={formData}>
      <div>
        <label>
          <input
            className="checkbox"
            value="email"
            type="checkbox"
            onChange={handleChecked}
          />
          Email
        </label>
      </div>
      {EmailChecked ? (
        <div className="FormEmailDiv">
          <FontAwesomeIcon icon={faEnvelope} size="2x" />

          <input name="email" className="inputbox" placeholder="Email" />
        </div>
      ) : (
        <div> </div>
      )}
      <div>
        <label>
          <input
            className="checkbox"
            value="sms"
            type="checkbox"
            onChange={handleChecked}
          />
          SMS
        </label>
      </div>
      {SmsChecked ? (
        <PhoneInput
          className="phoneInput"
          placeholder="Enter phone number"
          name="phoneNumber"
          onChange={(value) => (phoneValue.current = value)}
        />
      ) : (
        <div> </div>
      )}
      <div className="FormBtnDiv">
        <button type="submit">Submit</button>
      </div>
    </form>
  );
}
