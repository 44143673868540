
const initialState = 
    {
        noOfItemInCart : 0,
        cartTotal : 0.00,
        cart : []
    }

function cartReducer(state = initialState, action) {
    // Reducers usually look at the type of action that happened
    // to decide how to update the state
    switch (action.type) {
      case "ADD_ITEM_TO_CART":
        return {
          ...state,
          noOfItemInCart: state.noOfItemInCart + 1,
          cartTotal : state.cartTotal + (parseFloat(action.payload.cart.price) * parseFloat(action.payload.noOfItem) ) ,
          cart : [
              ...state.cart,
              action.payload
          ]
      };
      case "DELETE_ITEM_FROM_CART":
        return { ...state, value: state.value - 1 };
      default:
        // If the reducer doesn't care about this action type,
        // return the existing state unchanged
        return state;
    }
  }

  export default cartReducer