const sampleData = [
    {
        id : 1,
        brand : 'Stars29',
        description : 'Crayola Edition SS23',
        price : '35.00',
        colours : ["#fff", "#000000"],
        image : 'https://ik.imagekit.io/gnqpjyacq/Stars29/sample-front_xZgNrmWtF.png?updatedAt=1697553182748',
        category : "T-Shirts",
        sizes : ["S" , "M" , "L", "XL", "XXL"]
    },
    {
        id : 2,
        brand : 'Stars29',
        description : 'Crayola Edition SS23',
        price : '35.00',
        colours : ["#fff"],
        image : 'https://ik.imagekit.io/gnqpjyacq/Stars29/sample-front_xZgNrmWtF.png?updatedAt=1697553182748',
        category : "T-Shirts",
        sizes : ["S" , "M" , "L", "XL", "XXL"]
    },
    {
        id : 3,
        brand : 'Vans',
        description : 'Old Skool Platform',
        price : '110.00',
        colours : ["#000000"],
        image : 'https://ik.imagekit.io/gnqpjyacq/Stars29/vans_RTer_-fbW.jpg?updatedAt=1697552552883',
        category : "Shoes",
        sizes : ["5" , "6" , "7", "8", "9"]
    }
]

export default sampleData;