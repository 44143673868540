import React, { Component } from 'react';

export default function CartItem(props){

    const amount = ()=>{
        var price = props.data.price
        price = price.replace("US$","")
        return parseFloat(price) * parseFloat(props.quantity)
    }
    return(
        <div className='CartItem'>
        
            <div className='CartItem-Innerwrapper'>
                <div className='cartImg'>
                <img loading='lazy' className='CartItem-Img' src={props.data.image} alt={props.data.brand}/>
                </div> 
                
            
            <div className='CartItem-Details'>
            <p className='brand'>{props.data.brand}</p>
            <p className='CartItem-colour'>{props.data.description}</p>
            <div className='CartItem-Sizes'>
            <p >{props.data.sizes}</p>
            </div>
            
            <div className='CartItem-bottom'>
            <p className='CartItem-price'>US${amount()}</p>
            <div className='CartItem-quantity'>
                <p>No. of Items : {props.quantity}</p>
            </div>
            </div>
            </div>

            </div>
        </div>
    )
}
