import React, { Component } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import "./FrontPage.css";

export default function FrontPage2() {
  const { scrollYProgress } = useScroll({
    offset: ["start", "center"],
  });

  return (
    <section className="FrontPage">
      <div className="HeaderText">
        <h1>Stars29. Crayola Season Collection 2023</h1>
        <p>
          Our collection is designed for those who dare to be different. From
          statement prints to bold colors, our apparel will help you showcase
          your unique personality with confidence.
        </p>
      </div>
      <div className="HeaderIMG">
        <img
          loading="lazy"
          src="https://ik.imagekit.io/gnqpjyacq/Stars29/shoot-3.jpg?updatedAt=1699806009817"
          alt="Header"
        />
        <p className="SideText">CRAYOLA</p>
      </div>
    </section>
  );
}
